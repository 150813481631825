import React, { useEffect, useRef } from 'react'
import '../styles/footer.scss'
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { GrMail } from "react-icons/gr";
import FooterDivider from './FooterDivider';
import emailjs from '@emailjs/browser';
import { firestore } from '../firebase/index';
import { useState } from 'react';
import { collection, onSnapshot, addDoc } from 'firebase/firestore';
function Footer() {

    const [allEmails, setAllEmail] = useState([])

    const [email, setEmail] = useState([])

    const formRef = useRef();

    useEffect(() => {
        const getdata = onSnapshot(collection(firestore, "mailing-list"), (snapshot) => {
            setAllEmail(snapshot.docs.map(doc => doc.data()))
        })
        return getdata
    }, [])

    const formData = {
        user_email: email,
        from_email: 'gorkhalikitchentampa@gmail.com',
    }

    const sendEmail = () => {
        emailjs.send(process.env.REACT_APP_SERVICE_ID, "template_kyao4ue", formData, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                console.log('SUCCESS!');
            }, (error) => {
                console.log("error=>", error.text);
            });
    };

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const handleNew = async (e) => {
        const CollectionRef = collection(firestore, "mailing-list");
        // eslint-disable-next-line no-useless-escape
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
            const filterMail = allEmails.filter(d => d.email === email)
            if (filterMail.length === 0) {
                const payload = { email: email.toLowerCase() }
                await addDoc(CollectionRef, payload);
                sendEmail();
                alert("Email Added Successfully");
                setEmail("")
            } else {
                alert("Email already exist")
                setEmail("")
            }
        } else if (email.length === 0) {
            alert("Please enter email")
        } else {
            alert("Email is not valid")
            setEmail("")
        }
    }



    return (
        <footer className="main_footer">
            <div className="container">
                <div className="row footer_main_content">
                    <div className="col-md-12 col-lg-7">
                        <div className="row">
                            <div className="col-md-4 mb-md-0">
                                <h3 className="footer-heading">Contact Us</h3>
                                <div className='mb-4'>
                                    <span>
                                        <p className="d-block">10044 Cross Creek Blvd</p>
                                        <p className=" d-block">Tampa, FL 33647</p>
                                        <p>Phone: 813 388 6404</p>
                                        <p className='hoverEffect'>
                                            <a href="mailto:gorkhalikitchentampa@gmail.com" className=" d-block">gorkhalikitchentampa@gmail.com</a>
                                        </p>
                                        <div className="socialIcon_Container text-center">
                                            <ul className="list-unstyled">
                                                <li className='hoverEffect'><a href="https://www.facebook.com/Gorkhali-Kitchen-Tampa-105657878814101" target="_blank" rel='noreferrer' className="py-1 d-block"><BsFacebook /></a></li>
                                                <li className='hoverEffect'><a href="https://www.instagram.com/gorkhalikitchen/?igshid=YmMyMTA2M2Y=" target="_blank" rel='noreferrer' className="py-1 d-block"><AiFillInstagram /></a></li>
                                                <li className='hoverEffect'><a href="mailto:gorkhalikitchentampa@gmail.com" className="py-1 d-block"><GrMail /></a></li>
                                            </ul>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-8 mb-md-0">
                                <h2 className="footer-heading">Opening Hours</h2>
                                <div>
                                    <span>
                                        <p className=" d-block">Monday, Wednesday & Thursday  11:00 AM - 2:30 PM | 5:00 PM - 9:30 PM</p>
                                        <p className=" d-block">Friday - Sunday &nbsp; 11:00AM - 2:30 PM | 5:00 PM - 10:00 PM</p>
                                        <p className=" d-block">Tuesday Closed</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5">
                        <div className="row justify-content-end">
                            <div className="col-md-12 col-lg-12 mb-md-0 mb-4">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3518.1094467608445!2d-82.32873458255617!3d28.143156199999986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2b6ed87194819%3A0x79c61f6d31cf9ac6!2s10044%20Cross%20Creek%20Blvd%2C%20Tampa%2C%20FL%2033647%2C%20USA!5e0!3m2!1sen!2s!4v1652988249151!5m2!1sen!2s" width="100%" height="200" title='gorkhali kitchen' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                <h2 className="footer-heading mt-5">Join Our mailing List</h2>
                                <form className="subscribe-form" id='contact' ref={formRef}>
                                    <div className="form-group d-flex">
                                        <input value={email} type="text" name='user_email' className="form-control rounded-left" placeholder="Enter your email" onChange={handleChange} />
                                        <input onClick={handleNew} defaultValue="Subscribe" readOnly className="form-control submit px-3 rounded-right" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterDivider />
            </div>
        </footer>
    )
}

export default Footer
