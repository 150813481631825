import React, { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { ClickAwayListener } from '@mui/material';

const styles = {
    borderRight: {
        borderRight: '1px solid #F3F2F2',
        marginTop: '18px', marginBottom: '18px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    borderBottom: {
        // borderBottom: '1px solid #E8E8E8',
        lineHeight: '38px',
        backgroundColor: '#FFFFFF'
    }
}

function Header({ position }) {
    const [expanded, setExpanded] = useState(false)
    const [y, setY] = useState(0);
    // const scrollToTop = () => {
    //     window.scrollTo(0, 150)
    // }

    let handleOutsideClick = () => {
        console.log("clicked outside")
        setExpanded(false)
    }

    const handleNavigation = (e) => {
        const window = e.currentTarget;
        if (y > window.scrollY) {
            // console.log("scrolling up");
            setExpanded(false)
        } else if (y < window.scrollY) {
            // console.log("scrolling down");
            setExpanded(false)
        }
        setY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [y]);

    useEffect(() => {

        setY(window.scrollY);
    }, [])


    return (
        <>
            <div id='home'></div>
            <ClickAwayListener onClickAway={handleOutsideClick}>
                <Navbar id="scrollspyExample"
                    expand="md"
                    expanded={expanded}
                    variant="dark"
                    fixed={position ? 'top' : 'static'}
                    className='main_navbar landing'
                    style={styles.borderBottom}>
                    {/* <Container> */}

                    <div className='mobileView' style={{ display: 'none' }}>
                        <div className='headerBar d-flex justify-content-between align-items-center w-100 m-0 p-0' >
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded === true ? false : true)} />
                            <div style={{ width: '86px', height: '66px', overflow: 'hidden', marginRight: '7px' }}>
                                <a href="#home"><img
                                    src={require("../assets/113-by-87-PNG-white-logo.png")}
                                    className="d-inline-block align-top"
                                    alt="logo"
                                    style={{
                                        width: '100%'
                                    }}
                                /></a>
                            </div>
                        </div>
                    </div>
                    <Navbar.Brand href="#home" className="mobile-d-none" style={{
                        width: '125px',
                        height: '90px',
                        overflow: 'hidden',
                        marginLeft: '100px'
                    }}>
                        <img
                            // src="https://bhattipasal.nl/images/pasted-svg-112645x86.svg?crc=4269293285"
                            // src={require("../assets/Black-Logo-Final-Resize-113-by-87-PNG.png")}
                            src={require("../assets/113-by-87-PNG-white-logo.png")}
                            className="d-inline-block align-top"
                            alt="logo"
                            style={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                            }}
                        />
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="m_left">
                            <Nav.Link className="d-lg-flex justify-content-center align-items-center mx-md-3 mx-sm-2 theme-color" href="#home">HOME</Nav.Link>
                            <Nav.Link className="d-lg-flex justify-content-center align-items-center mx-md-3 mx-sm-2 theme-color" href="#story">OUR STORY</Nav.Link>
                            <Nav.Link className="d-lg-flex justify-content-center align-items-center mx-md-3 mx-sm-2 theme-color" href="#menu">MENU</Nav.Link>
                            <Nav.Link className="d-lg-flex justify-content-center align-items-center mx-md-3 mx-sm-2 theme-color" href="#contact">CONTACT</Nav.Link>
                            <Nav.Link className="d-lg-flex justify-content-center align-items-center mx-md-3 mx-sm-2 theme-color" href="https://www.clover.com/online-ordering/gorkhali-kitchen-tampa" target='_blank'>ORDER NOW</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    {/* </Container> */}
                </Navbar>
            </ClickAwayListener>
        </>
    )
}

export default Header