import React from 'react'
import { FiHeart } from "react-icons/fi";
function FooterDivider() {
    return (
        <div className='mt-5'>
            <div className="row">
                <div className="col-md-12">
                    <hr className="underline" />
                </div>
                <div className="col-md-8">
                    <p className="copyright">
                        © {new Date().getFullYear()} <a href="/" title="GORKHALI KITCHEN official website | Authentic Nepali cuisines in Kathmandu">GORKHALI KITCHEN</a>. All rights reserved.
                    </p>
                </div>
                <div className="col-md-4 ftco-animate fadeInUp ftco-animated">
                    <p className="footerCredits">
                        <span>Crafted with </span><FiHeart color='red' fill='red' />&nbsp;<span className="coeval">by NexArt</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FooterDivider