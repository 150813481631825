import Home from '../pages/index';
import AdminLogin from '../pages/Login';
import AdminDashboard from '../pages/Dashboard';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import ProtectedRoutes from './auth'
function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" index element={<Home />} />
                    <Route path="/admin/login" index element={<AdminLogin />} />
                    <Route element={<ProtectedRoutes />}>
                        <Route path="/admin/dashboard" element={<AdminDashboard />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}
export default App;
