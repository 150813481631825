import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { logout } from '../firebase/loginAuth'
import { Col, Row, Form, InputGroup, Navbar, Container, } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase/index';
import Button from '@mui/material/Button';
import AllMails from '../components/AllMails';

function Dashboard() {

    const [validated, setValidated] = useState(false);
    const [fromEmail, setFromEmail] = useState('gorkhalikitchentampa@gmail.com');
    const [fromName, setFromName] = useState('Gorkhali Kitchen');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [allEmails, setAllEmail] = useState([])

    const navigate = useNavigate()
    const logoutUser = () => {
        navigate('/admin/login')
        logout()
    }


    const formRef = useRef();

    const FormData = {
        subject: subject,
        from_name: fromName,
        from_email: fromEmail,
        message: message
    }

    const eachData = allEmails.map((data) => {
        return { ...FormData, user_email: data.email }
    })
    const sendEmail = () => {
        eachData.forEach((data) => {
            emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, data, process.env.REACT_APP_PUBLIC_KEY)
                .then((result) => {
                    console.log('SUCCESS!');
                }, (error) => {
                    console.log("error=>", error.text);
                });
        })
    };

    useEffect(() => {
        const getdata = onSnapshot(collection(firestore, "mailing-list"), (snapshot) => {
            setAllEmail(snapshot.docs.map(doc => doc.data()))
        })
        return getdata
    }, [])


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            // eslint-disable-next-line no-useless-escape
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (fromEmail.match(mailformat)) {
                sendEmail()
                alert("Successfyully Sent Email!")
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            } else {
                alert("Email format is not correct")

            }
        }

        setValidated(true);
    };

    return (
        <>
            <Navbar bg="dark" variant="dark" className='navbar-fixed' fixed='top'>
                <Container className='admin_dashboard_content'>
                    <Navbar.Brand className='admin_dashboard text-center mx-auto'>
                        <h1>Admin Dashboard</h1>
                    </Navbar.Brand>
                </Container>
                <div className='me-3'>
                    <Button
                        onClick={logoutUser}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Logout
                    </Button>
                </div>
            </Navbar>
            <div className="mainContainer">
                <div className="mainContainer__content adminDashboard__main__container">
                    <div className='main__container_gorkhali admin_dashboard_content'>
                        <Row>
                            <Col lg={12}>
                                <div className="mainContainer__content__title text-center py-2">
                                    <h1>Clients Email handler</h1>
                                </div>
                                <div className='mainContainer__content__description email_handle_form w85 mx-auto my-2'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Form.Label className='FormLabel mt-4 mb-0'>Subject <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    required
                                                    name="subject"
                                                    type="text"
                                                    placeholder="Subject"
                                                    value={subject}
                                                    onChange={(e) => setSubject(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">Please provide a valid subject</Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validationCustom02">
                                                <Form.Label className='FormLabel mt-4 mb-0'>From name <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    required
                                                    readOnly
                                                    name="from_name"
                                                    type="text"
                                                    placeholder="Sender Name"
                                                    value={fromName}
                                                    onChange={(e) => setFromName(e.target.value)}
                                                />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validationCustom02">
                                                <Form.Label className='FormLabel mt-4 mb-0'>From email <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name='from_email'
                                                    placeholder="Sender Email"
                                                    value={fromEmail}
                                                    onChange={(e) => setFromEmail(e.target.value)}
                                                    readOnly
                                                />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <InputGroup>
                                            </InputGroup>
                                            <Form.Group as={Col} md="12" controlId="validationCustom03">
                                                <Form.Label className='FormLabel mt-2 mb-0'>Message <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control
                                                    value={message}
                                                    name="message"
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    type="text" as="textarea"
                                                    placeholder="Enter Your Message"
                                                    required
                                                />
                                                <Form.Control.Feedback type="valid">
                                                    Looks good!
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid message.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                required
                                                label="Send to all the registered mails"
                                                feedback="You must agree before submitting."
                                                feedbackType="invalid"
                                            />
                                        </Form.Group>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Send
                                        </Button>

                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container mt-5">
                    <AllMails allEmails={allEmails} />
                </div>
            </div>
        </>
    )
}

export default Dashboard