import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/index.scss";
import michLogo from "../assets/michLogo.png";
import michLogo2024 from "../assets/michLogo-2024.png";

import { getDownloadURL, getStorage, ref } from "firebase/storage";
function Index() {
  const [downloadUrl, setDownloadUrl] = useState("");
  function listAllFiles() {
    const storage = getStorage();
    getDownloadURL(ref(storage, "menu/menu.pdf"))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          const blob = xhr.response;
          console.log(blob);
        };
        setDownloadUrl(url);
        // xhr.open('GET', url);
        // xhr.send();
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
        alert(error.message);
      });
  }

  useEffect(() => {
    listAllFiles();
  }, []);

  return (
    <div>
      <Header position={true} />
      <div className="mainContainer">
        <div className="mainContainer__content">
          {/* =========HOMEEE======= */}
          <div className="main__container_gorkhali">
            <Row>
              <Col lg={12}>
                <div className="mainContainer__content__title text-center">
                  <img src={michLogo2024} alt="" className="michLogo-24" />
                  <img src={michLogo} alt="" className="michLogo" />
                  <h1>'नमस्ते' WELCOME TO GORKHALI KITCHEN</h1>
                  <h2>Tampa, FL</h2>
                </div>
                <div className="mainContainer__content__description collageImageContainer w85 mx-auto my-2">
                  {/* <div className="kathmanduImage">
                                        <img src={(require('../assets/kathmandu3.jpg'))} alt="kathmandu" />
                                    </div> */}
                  {/* <Row className='image_container'>
                                        <Col xs={6} md={6} className="p-0">
                                            <div className="kathmanduImage">
                                                <img src={(require('../assets/collage1.jpg'))} alt="kathmandu" />
                                            </div>
                                        </Col>
                                        <Col xs={6} md={6} className="p-0">
                                            <div className="kathmanduImage">
                                                <img src={(require('../assets/collage2.jpg'))} alt="kathmandu" />
                                            </div>
                                        </Col>
                                        <Col xs={6} md={6} className="p-0">
                                            <div className="kathmanduImage">
                                                <img src={(require('../assets/collage3.jpg'))} alt="kathmandu" />
                                            </div>
                                        </Col>
                                        <Col xs={6} md={6} className="p-0">
                                            <div className="kathmanduImage">
                                                <img src={(require('../assets/collage4.jpg'))} alt="kathmandu" />
                                            </div>
                                        </Col>
                                    </Row> */}

                  <div className="gorkhali_image_container">
                    <div className="gorkhali_image">
                      <img
                        src={require("../assets/collage1.jpg")}
                        alt="kathmandu"
                      />
                      <img
                        src={require("../assets/collage2.jpg")}
                        alt="kathmandu"
                      />
                    </div>
                    <div className="gorkhali_image">
                      <img
                        src={require("../assets/collage3.jpg")}
                        alt="kathmandu"
                      />
                      <img
                        src={require("../assets/collage4.jpg")}
                        alt="kathmandu"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* =========OUR STORY======= */}
          <div
            className="marginDiv"
            style={{ height: "70px" }}
            id="story"
          ></div>
          <div className="main__container_gorkhali">
            <Row>
              <Col lg={12}>
                <div className="mainContainer__content__description w85 mx-auto my-2">
                  <div className="storyline">
                    <h4>OUR STORY</h4>
                    <p>
                      Realizing limited options of Himalayan flavors, the four
                      friends began hatching a plan and with a lot of love and
                      patience, Gorkhali Kitchen opened for business in 2022.
                    </p>
                    <p>
                      We are locally owned and dedicated to serving up great
                      Himalayan-inspired, authentic flavors of Kathmandu City
                      for lunch and dinner. Welcome to our restaurant, we hope
                      you will love it as much as we do!
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="bgImage"></div>
          {/* =========MENU======= */}
          <div className="marginDiv" style={{ height: "70px" }} id="menu"></div>
          <div className="main__container_gorkhali">
            <Row>
              <div className="menu_section">
                <img
                  src={require("../assets/menu_img.jpg")}
                  alt="menu img"
                  width="369"
                  height="246"
                />
                <button style={{ border: "none", background: "none" }}>
                  <a href={downloadUrl} download>
                    <span>MENU</span>
                  </a>
                </button>

                <p className="w85">
                  We prepare our dishes with fresh, local and seasonal
                  ingredients.
                </p>
              </div>
            </Row>
          </div>
          {/* =========JOIN OUR TEAM======= */}
          <div className="main__container_gorkhali">
            <Row>
              <div className="mainContainer_join my-lg-5">
                <div className="mainContainer__content__title py-2">
                  <h1>Join Our Team</h1>
                  <h2>Best Nepalese Restaurant</h2>
                </div>
                <div className="memo_para text-center">
                  <p className="w85 text-center">
                    We are looking for restaurant service and kitchen staff with
                    great attitude, who is ready and eager to work in a fast and
                    dynamic restaurant/kitchen. Full time/Part time.
                  </p>
                  <p>
                    Contact us 813 388 6404 or via email:
                    gorkhalikitchentampa@gmail.com
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
