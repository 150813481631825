import React from 'react'
import { Table, Alert } from 'react-bootstrap'
function AllMails({ allEmails }) {
    return (
        <div className='mb-5'>
            <div className='mainContainer__content__title'>

                <h1>Total Registered Clients: {allEmails.length}</h1>
            </div>
            {
                allEmails?.length !== 0 ?
                    < Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allEmails.map((data, val) => {
                                    return (
                                        <tr key={val}>
                                            <td>{val + 1}</td>
                                            <td>{data.email}</td>
                                        </tr>

                                    )
                                })
                            }
                        </tbody>
                    </Table> : <Alert variant="info"> No email in database</Alert>
            }
        </div >
    )
}

export default AllMails