import { firebaseApp } from './index'
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";

const auth = getAuth(firebaseApp);
const logInWithEmailAndPassword = async (email, password, setMessage) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        localStorage.setItem('admin', email)
    } catch (err) {
        if (err.message) {

            setMessage('Invalid Email or Password')
        }
    }
};

const logout = () => {
    signOut(auth);
    localStorage.removeItem('admin')
};
export {
    auth,
    logInWithEmailAndPassword,
    logout,
};